<template>
    <!-- 您的模板內容 -->
    <v-container fluid>
        <div style="margin-bottom: 15px;">
            <v-card class="mx-auto pa-5 pb-8" elevation="8" max-width="" rounded="lg">
                <h1 class="text-h5 mb-5">
                    Edit {{ UserType }}
                </h1>
                <v-row>

                    <v-col cols="12">
                        <h1 class="text-h6 mb-2">
                            Account
                        </h1>
                        <v-chip>
                            {{ Account }}
                        </v-chip>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="UserName *" v-model="UserName" required
                            :rules="[validateNoSpecialChars]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Password *" v-model="Password" required
                            :rules="[validateNoSpecialChars]"></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="Password2Edit">
                        <v-text-field label="Password2 *" v-model="Password2" required
                            :rules="[validateNoSpecialChars]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Phone" v-model="Phone" :rules="[validatePhoneNumber]"></v-text-field>
                    </v-col>
                    <v-btn block class="mb-8" color="blue" size="large" variant="tonal" @click="EditUser">Update</v-btn>
                </v-row>


            </v-card>
        </div>

    </v-container>
</template>
  
<script>
import axios from 'axios';
export default {
    data() {
        return {
            Password2Edit: false,
            UserID: null,
            UserType: null,
            Account: null,
            UserName: null,
            Password: null,
            Phone: null,
            Password2: null,
        }
    },
    created() {

        this.getCookieValue();
        this.Password2Fun();
        this.loadUserData();
    },
    methods: {
        validateEmail(value) {
            // 使用正则表达式检查输入的值是否是有效的电子邮件地址
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return pattern.test(value) || 'Please enter a valid email address.';
        },
        validateNoSpecialChars(value) {
            // 使用正则表达式检查输入的值是否包含特殊字符
            const pattern = /^[a-zA-Z0-9_.]*$/;
            return pattern.test(value) || 'Please enter a valid value without special characters.';
        },
        validatePhoneNumber(value) {
            const phoneNumberPattern = /^[0-9+-]*$/;
            return phoneNumberPattern.test(value) || 'Please enter a valid phone';
        },
        Password2Fun() {
            if (localStorage.getItem('AgentID') == '1' && this.UserType == "Agent") {
                this.Password2Edit = true;
            }

        },
        async loadUserData() {
            if (this.UserID == null) {
                this.$router.push("/");
            } else {
                const formData = new FormData();
                formData.append('UserID', this.UserID);
                formData.append('UserType', this.UserType);
                axios.post('https://amoikiss.com/API/Manager/SearchUser.php', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                })
                    .then(response => {
                        // console.log(response.data);
                        this.Account = response.data.Account;
                        this.UserName = response.data.UserName;
                        this.Email = response.data.Email;
                        this.Phone = response.data.Phone;
                        // this.PlayerList = response.data;
                        // this.selectedPlayer = "5";
                    });
            }
        },
        EditUser() {

            const formData = new FormData();
            formData.append('MyAgentID', localStorage.getItem('AgentID'));
            formData.append('UserID', this.UserID);
            formData.append('UserType', this.UserType);
            formData.append('UserName', this.UserName);
            formData.append('Phone', this.Phone);
            formData.append('Password', this.Password);
            formData.append('Password2', this.Password2);

            axios.post('https://amoikiss.com/API/Manager/EditUser.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    alert(response.data.message);
                    if (!response.data.error) {
                        this.$router.push("/");
                    }
                });

        },
        getCookieValue() {
            // 獲取 cookie 字串
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();

                if (cookie.startsWith("UserID=")) {
                    this.UserID = cookie.substring("UserID=".length, cookie.length);
                    document.cookie = "UserID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                }

                if (cookie.startsWith("Type=")) {
                    this.UserType = cookie.substring("Type=".length, cookie.length);
                    document.cookie = "Type=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                }
            }

        },

    },
};
</script>
  