<template>
    <!-- 您的模板內容 -->
    <v-container fluid>

        <h1 class="text-h5 mb-5">
            Operation
        </h1>


        <!-- First Table -->
        <v-row no-gutters>
            <v-col cols="12">
                <v-card flat>
                    <div class="table-container">
                        <v-data-table id="UserTable" :headers="Headers" :items="Desserts" :loading="loading"
                            loading-text="Loading... Please wait">
                            <template v-slot:body="{ items }">
                                <tr v-for="(item, index) in items" :key="index">
                                    <td>{{ item.ID }}</td>
                                    <td>{{ item.Function }}</td>
                                    <td>{{ item.Content }}</td>

                                </tr>

                            </template>
                        </v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
import axios from 'axios';
export default {
    data() {
        return {

            Headers: [
                { key: 'ID', title: '#' },
                { key: 'Function', title: 'Function' },
                { key: 'Content', title: 'Content' }
            ],
            Desserts: [],

        }
    },
    created() {

        this.loadPlayerList();
    },
    methods: {
        async loadPlayerList() {
            const formData = new FormData();
            formData.append('AgentID', localStorage.getItem('AgentID'));
            axios.post('https://amoikiss.com/API/Manager/ShowOperation.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    console.log(response.data);
                    this.Desserts = response.data;
                    // this.selectedPlayer = "5";
                });

        },

    },
};
</script>
  
<style></style>
  