<template>
    <v-container fluid>
        <!-- First Table -->
        <v-row no-gutters>
            <v-col cols="12">
                <v-card flat>


                    <h2>
                        <v-btn v-if="Agent.length > 1" prepend-icon="mdi-keyboard-backspace" variant="plain"
                            @click="AgentBack"></v-btn>
                        Agent <v-chip prepend-icon="mdi-clipboard" size="x-small" class="ma-2" color="indigo">
                            MaxScore - {{ AgentScore }}
                        </v-chip>
                    </h2>

                    <v-breadcrumbs>
                        <template v-slot:prepend>
                            <v-chip prepend-icon="mdi-account-outline" size="x-small" class="ma-2" color="orange">
                                Agent - {{ Agent[Agent.length - 1].Name }}
                            </v-chip>
                        </template>
                        <v-btn v-if="Agent.length == 1" variant="text" @click="openDialog('AddAgent')">Add Agent</v-btn>

                        <v-btn v-if="AgentTable" variant="text"
                            @click="TotalAgentReport(Agent[Agent.length - 1].ID, Agent[Agent.length - 1].Name)">Total Agent
                            Report</v-btn>

                        <div class="ml-auto"><i class="mdi " :class="AgentTable ? 'mdi-eye' : 'mdi-eye-off'"
                                @click="AgentTable = !AgentTable"></i></div>
                    </v-breadcrumbs>
                    <!-- <div class="table-container" v-if="AgentTable">
                        <v-data-table id="UserTable" :headers="AgentHeaders" :items="AgentDesserts">
                        </v-data-table>
                    </div> -->
                    <div class="table-container" v-if="AgentTable">
                        <v-data-table id="UserTable" :headers="AgentHeaders" :items="AgentDesserts">
                            <template v-slot:body="{ items }">
                                <tr v-for="(item, index) in items" :key="index">
                                    <td>{{ item.ID }}</td>
                                    <td><v-btn variant="plain" @click="SetAgent(item.AgentID, item.UserName);">{{
                                        item.UserName }}</v-btn></td>
                                    <td>{{ item.Score }}</td>
                                    <td>{{ item.Phone }}</td>
                                    <td class="custom-operation">
                                        <!-- Your custom button goes here -->
                                        <v-btn size="x-small" v-if="Agent.length == 1"
                                            @click="openDialog('AgentSetScore'); SetScoreNow(item.Score, item.AgentID);">set
                                            score</v-btn>
                                        <v-btn size="x-small" @click="CheckScoreLog(item.AgentID, item.UserName);">score
                                            log</v-btn>
                                        <v-btn size="x-small" @click="EditUser(item.AgentID, 'Agent');">Edit</v-btn>
                                        <v-btn size="x-small" @click="TotalAgentReport(item.AgentID, item.UserName)">
                                            Report</v-btn>
                                        <v-btn size="x-small" :color="(item.Status == 1) ? '' : 'deep-orange-darken-4'"
                                            @click="AgentStatus(item)">
                                            {{ (item.Status == 1) ? 'enable' : 'disable' }}</v-btn>
                                    </td>

                                </tr>

                            </template>
                        </v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <!-- Second Table -->
        <v-row no-gutters>
            <v-col cols="12">
                <v-card flat title="Player">
                    <v-breadcrumbs :items="PlayerItems">
                        <v-btn v-if="Agent.length == 1" variant="text" @click="openDialog('AddPlayer')">Add Player</v-btn>
                        <div class="ml-auto"><i class="mdi " :class="PlayerTable ? 'mdi-eye' : 'mdi-eye-off'"
                                @click="PlayerTable = !PlayerTable"></i></div>
                    </v-breadcrumbs>


                    <div class="table-container" v-if="PlayerTable">
                        <v-data-table id="UserTable" :headers="PlayerHeaders" :items="PlayerDesserts">
                            <template v-slot:body="{ items }">
                                <tr v-for="(item, index) in items" :key="index">
                                    <td>{{ item.ID }}</td>
                                    <td>{{ item.UserName }}</td>
                                    <td>{{ item.Score }}</td>
                                    <td>{{ item.Phone }}</td>
                                    <td class="custom-operation">
                                        <!-- Your custom button goes here -->
                                        <v-btn size="x-small" v-if="Agent.length <= 1"
                                            @click="openDialog('PlayerSetScore'); SetScoreNow(item.Score, item.PlayerID);">set
                                            score</v-btn>
                                        <v-btn size="x-small" @click="CheckGameLog(item.PlayerID);">Game
                                            log</v-btn>
                                        <v-btn size="x-small" @click="EditUser(item.PlayerID, 'Player');">Edit</v-btn>
                                        <v-btn size="x-small" :color="(item.Status == 1) ? '' : 'deep-orange-darken-4'"
                                            @click="PlayerStatus(item)">
                                            {{ (item.Status == 1) ? 'enable' : 'disable' }}</v-btn>
                                    </td>

                                </tr>

                            </template>
                        </v-data-table>
                    </div>

                </v-card>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="Dialog" persistent width="1024">
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <span class="text-h5" v-if="AddAgent">Add Agent</span>
                <span class="text-h5" v-if="AddPlayer">Add Player</span>
                <span class="text-h5" v-if="AgentSetScoreDialog">Agent Set Score</span>
                <span class="text-h5" v-if="PlayerSetScoreDialog">Player Set Score</span>


                <v-btn color="blue-darken-1" variant="text" @click="closeDialog">
                    <i class="mdi mdi-window-close text-h5"></i>
                </v-btn>
            </v-card-title>
            <form v-if="AddAgent" @submit.prevent="AddAgentForm"> <!-- 阻止默认的表单提交行为 -->
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="custom-margin">
                                <v-text-field label="Account *" v-model="AddAgentData.Account" required
                                    :rules="[validateNoSpecialChars]"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="custom-margin">
                                <v-text-field label="Password *" v-model="AddAgentData.Password" required
                                    :rules="[validateNoSpecialChars]"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="custom-margin">
                                <v-text-field label="Username *" v-model="AddAgentData.Username" required
                                    :rules="[validateNoSpecialChars]"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="custom-margin">
                                <v-text-field label="Phone" v-model="AddAgentData.Phone"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn type="submit" color="blue-darken-1" variant="text">
                        Save
                    </v-btn>
                </v-card-actions>
            </form>
            <form v-if="AddPlayer" @submit.prevent="AddPlayerForm"> <!-- 阻止默认的表单提交行为 -->
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="custom-margin">
                                <v-text-field label="Account *" v-model="AddPlayerData.Account" required readonly
                                    :rules="[validateNoSpecialChars]"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="custom-margin">
                                <v-text-field label="Password *" v-model="AddPlayerData.Password" required
                                    :rules="[validateNoSpecialChars]"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="custom-margin">
                                <v-text-field label="Username *" v-model="AddPlayerData.Username" required
                                    :rules="[validateNoSpecialChars]"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="custom-margin">
                                <v-text-field label="Phone" v-model="AddPlayerData.Phone"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn type="submit" color="blue-darken-1" variant="text">
                        Save
                    </v-btn>
                </v-card-actions>
            </form>
            <form v-if="AgentSetScoreDialog || PlayerSetScoreDialog" @submit.prevent="SetScoreForm"> <!-- 阻止默认的表单提交行为 -->
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="custom-margin">
                                Current score <v-chip size="x-small" class="ma-2" color="indigo-darken-1">
                                    {{ SetScore.Score }}
                                </v-chip>
                            </v-col>
                            <v-col cols="12" class="custom-margin">
                                set score
                                <v-chip size="x-small" class="ma-2" color="red-darken-4">
                                    MaxScore - {{ AgentScore }}
                                </v-chip>
                                <v-text-field label="Score *" v-model="SetScoreData.Score" required
                                    :rules="[validateNumber, validateLessThanAgentScore]"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn type="submit" :disabled="!isFormValid" color="blue-darken-1" variant="text">
                        Save
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            Agent: [
                { ID: localStorage.getItem('AgentID'), Name: localStorage.getItem('AgentName') }
            ],
            AgentScore: 0,
            AddAgent: false,
            AddPlayer: false,
            AgentSetScoreDialog: false,
            PlayerSetScoreDialog: false,
            Dialog: false,
            AgentTable: true,
            PlayerTable: true,
            isFormValid: false,
            SetScore: {
                ID: 0,
                Score: 0
            },
            AddAgentData: {
                Account: '',
                Password: '',
                Username: '',
                Email: '',
                Phone: '',
            },
            AddPlayerData: {
                Account: '',
                Password: '',
                Username: '',
                Email: '',
                Phone: '',
            },
            SetScoreData: {
                Score: ''
            },
            AgentHeaders: [
                { key: 'ID', title: '#', },
                { key: 'UserName', title: 'UserName' },
                { key: 'Score', title: 'Score' },
                { key: 'Phone', title: 'Phone' },
                { title: 'Operation', class: 'custom-operation-title' }
            ],
            AgentDesserts: [],
            PlayerHeaders: [
                {
                    key: 'ID',
                    title: '#',
                },
                { key: 'UserName', title: 'UserName' },
                { key: 'Score', title: 'Score' },
                { key: 'Phone', title: 'Phone' },
                { title: 'Operation' }
            ],
            PlayerDesserts: [],
        }
    },
    created() {
        // 在组件创建时加载数据
        this.loadAgentData();
        this.loadPlayerData();
        this.loadAgentScore();
    },
    watch: {
        AddPlayer(newVal) {
            if (newVal) {
                this.generateRandomAccount();
            }
        },
    },
    methods: {
        async AgentStatus(item) {
            const newStatus = item.Status == 1 ? 0 : 1;
            const formData = new FormData();
            formData.append('MyAgentID', localStorage.getItem('AgentID'));
            formData.append('AgentID', item.AgentID);
            formData.append('Status', newStatus);
            axios.post('https://amoikiss.com/API/Manager/AgentStatus.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    if (response.data.success) {
                        // 更新item的状态
                        item.Status = newStatus;
                    } else {
                        console.error('POST请求失败');
                    }
                });


        },
        async PlayerStatus(item) {
            const newStatus = item.Status == 1 ? 0 : 1;
            const formData = new FormData();
            formData.append('MyAgentID', localStorage.getItem('AgentID'));
            formData.append('PlayerID', item.PlayerID);
            formData.append('Status', newStatus);
            axios.post('https://amoikiss.com/API/Manager/PlayerStatus.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    if (response.data.success) {
                        // 更新item的状态
                        item.Status = newStatus;
                    } else {
                        console.error('POST请求失败');
                    }
                });
        },
        TotalAgentReport(MyID, MyName) {
            // const lastAgent = this.Agent[this.Agent.length - 1].ID;
            // const lastAgentName = this.Agent[this.Agent.length - 1].Name;
            document.cookie = "UserID=" + MyID + ";";
            document.cookie = "UserName=" + MyName + ";";
            this.$router.push("/TotalAgentReport");
        },
        EditUser(MyID, UserType) {
            document.cookie = "UserID=" + MyID + ";";
            document.cookie = "Type=" + UserType + ";";
            this.$router.push("/EditUser");
        },
        CheckScoreLog(MyID, MyName) {
            document.cookie = "UserID=" + MyID + ";";
            document.cookie = "UserName=" + MyName + ";";
            this.$router.push("/ScoreLog");
        },
        CheckGameLog(MyID) {
            document.cookie = "PlayerID=" + MyID + ";";
            this.$router.push("/GameLog");
        },
        GameIN(GamePlayerID, GameCode) {
            window.onbeforeunload = function () {
                // 此處可以執行一些操作，表示用戶正在離開頁面
                // 注意：這不是一個可靠的方法，因為它也會在刷新頁面時觸發
                console.log("User is leaving the page. Performing cleanup operations...");
            };
            const Link = "https://amoikiss.com/API/EpicWin/GameLogin.php?GameCode=" + GameCode + "&PlayerId=" + GamePlayerID;
            axios.get(Link, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }).then(response => {
                if (response.data.Status == 200) {
                    window.open(response.data.Url, '_blank');
                }
                // 在这里处理服务器的响应，如果有需要的话
            })
        },
        AgentBack() {
            this.Agent.pop();
            this.loadAgentData();
            this.loadAgentScore();
            this.loadPlayerData();
        },
        SetAgent(AgentID, AgentName) {
            // this.AgentID = Agent;
            // this.AgentName = AgentName;
            this.Agent.push({
                ID: AgentID,
                Name: AgentName
            });
            this.loadAgentData();
            this.loadAgentScore();
            this.loadPlayerData();
        },
        SetScoreNow(Score, Agent) {
            this.SetScore.ID = Agent;
            this.SetScore.Score = Score;
            // this.AgentSetScore = Score;
            // this.AgentSet = Agent;

        },
        validateNumber(value) {
            const numberRegex = /^-?\d+(\.\d+)?$/;
            this.isFormValid = numberRegex.test(value);
            return numberRegex.test(value) || 'Please enter a valid number';
        },
        validateLessThanAgentScore(value) {
            const numericValue = parseFloat(value);
            this.isFormValid = numericValue <= this.AgentScore;
            return numericValue <= this.AgentScore || `Score must be less than ${this.AgentScore}`;
        },
        closeDialog() {
            this.AddAgent = false;
            this.AddPlayer = false;
            this.AgentSetScoreDialog = false;
            this.PlayerSetScoreDialog = false;
            for (let key in this.AddAgentData) {
                this.AddAgentData[key] = '';
            }
            for (let key in this.AddPlayerData) {
                this.AddPlayerData[key] = '';
            }
            for (let key in this.SetScoreData) {
                this.SetScoreData[key] = '';
            }

            this.Dialog = false;
        },
        openDialog(Type) {
            this.isFormValid = false;
            if (Type == "AddAgent") {
                this.AddAgent = true;
            }
            if (Type == "AddPlayer") {
                this.AddPlayer = true;
            }
            if (Type == "AgentSetScore") {
                this.AgentSetScoreDialog = true;
            }
            if (Type == "PlayerSetScore") {
                this.PlayerSetScoreDialog = true;
            }

            this.Dialog = true;
        },

        validateEmail(value) {
            // 使用正则表达式检查输入的值是否是有效的电子邮件地址
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return pattern.test(value) || 'Please enter a valid email address.';
        },
        validateNoSpecialChars(value) {
            // 使用正则表达式检查输入的值是否包含特殊字符
            const pattern = /^[a-zA-Z0-9_.]*$/;
            return pattern.test(value) || 'Please enter a valid value without special characters.';
        },
        async loadAgentScore() {
            const lastAgent = this.Agent[this.Agent.length - 1].ID;
            try {
                const response = await axios.get('https://amoikiss.com/API/Manager/AgentScore.php?AgentID=' + lastAgent);
                if (Array.isArray(response.data)) {

                    this.AgentScore = response.data[0].Score;
                } else {
                    console.error('请求成功，但未收到有效的数据。');
                }
            } catch (error) {
                console.error('加载数据时发生错误:', error);
            }
        },
        async loadAgentData() {
            const lastAgent = this.Agent[this.Agent.length - 1].ID;
            try {
                const response = await axios.get('https://amoikiss.com/API/Manager/AgentList.php?AgentID=' + lastAgent);
                if (Array.isArray(response.data)) {
                    // 将数据分配给 AgentDesserts
                    // console.log(response.data);
                    this.AgentDesserts = response.data;
                } else {
                    console.error('请求成功，但未收到有效的数据。');
                }
            } catch (error) {
                console.error('加载数据时发生错误:', error);
            }
        },
        async loadPlayerData() {
            const formData = new FormData();
            const lastAgent = this.Agent[this.Agent.length - 1].ID;
            formData.append('AgentID', lastAgent);
            axios.post('https://amoikiss.com/API/Manager/PlayerList.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    this.PlayerDesserts = response.data;
                });

            // try {
            //     const response = await axios.get('https://amoikiss.com/API/Manager/PlayerList.php?AgentID=' + lastAgent);
            //     if (Array.isArray(response.data)) {

            //         // 将数据分配给 AgentDesserts
            //         // console.log(response.data);
            //         this.PlayerDesserts = response.data;
            //     } else {
            //         console.error('请求成功，但未收到有效的数据。');
            //     }
            // } catch (error) {
            //     console.error('加载数据时发生错误:', error);
            // }
        },
        async generateRandomAccount() {
            try {
                const response = await axios.get('https://amoikiss.com/API/Manager/RandomPlayerAccount.php');
                console.log(response.data);
                // 将获取的随机账号赋值给 AddPlayerData.Account
                this.AddPlayerData.Account = response.data[0].Account;

            } catch (error) {
                console.error('请求随机账号时发生错误:', error);
            }
        },
        SetScoreForm() {
            this.isFormValid = false;
            let url = 'https://amoikiss.com/API/Manager/SetAgentScore.php';
            this.SetScoreData.ToType = (this.AgentSetScoreDialog) ? "Agent" : "Player";
            this.SetScoreData.ToID = this.SetScore.ID;
            this.SetScoreData.FromID = this.Agent[this.Agent.length - 1].ID;
            if (this.PlayerSetScoreDialog) {
                url = 'https://amoikiss.com/API/Manager/SetPlayerScore.php';
            }
            axios.post(url, this.SetScoreData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    console.log(response.data)
                    if (response.data.success) {

                        // alert(response.data.message);
                        this.Dialog = false;
                        this.AgentSetScoreDialog = false;
                        this.PlayerSetScoreDialog = false;
                        for (let key in this.SetScoreData) {
                            this.SetScoreData[key] = '';
                        }
                        this.loadAgentData();
                        this.loadPlayerData();
                        this.loadAgentScore();
                    } else {
                        alert(response.data.message);
                    }

                });

        },

        AddAgentForm() {
            // 使用 Axios 发送 POST 请求
            // Include AgentID in the data to be sent
            const lastAgent = this.Agent[this.Agent.length - 1].ID;
            this.AddAgentData.AgentID = lastAgent;
            axios.post('https://amoikiss.com/API/Manager/AddAgent.php', this.AddAgentData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    if (response.data.success) {
                        this.Dialog = false;
                        this.AddAgent = false;
                        for (let key in this.AddAgentData) {
                            this.AddAgentData[key] = '';
                        }
                        this.loadAgentData();
                    }
                });

        },
        AddPlayerForm() {
            const lastAgent = this.Agent[this.Agent.length - 1].ID;
            this.AddPlayerData.AgentID = lastAgent;

            axios.post('https://amoikiss.com/API/Manager/AddPlayer.php', this.AddPlayerData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {

                    if (response.data.success) {
                        this.Dialog = false;
                        this.AddPlayer = false;
                        for (let key in this.AddPlayerData) {
                            this.AddPlayerData[key] = '';
                        }
                        this.loadPlayerData();
                    }

                });
        },
    }

}
</script>
<style>
.table-container {
    overflow: auto;
}

#UserTable thead {
    background-color: #512DA8;
    position: sticky;
    top: 0;
    z-index: 1;
}

#UserTable thead th {
    color: white;
}

.MyBtn {
    font: 0.7em !important;
    width: 10px;
}

.custom-operation {
    width: 30%;
}
</style>