import { createRouter, createWebHistory } from 'vue-router'
import MyUser from '@/components/MyUser.vue'
import MyScoreLog from '@/components/MyScoreLog.vue'
import MyGameLog from '@/components/MyGameLog.vue'
import MyEditUser from '@/components/MyEditUser.vue'
import MyTotalAgentReport from '@/components/MyTotalAgentReport.vue'
import MyOperation from '@/components/MyOperation.vue'

const routes = [
    { path: '/', component: MyUser },
    { path: '/ScoreLog', component: MyScoreLog },
    { path: '/GameLog', component: MyGameLog },
    { path: '/EditUser', component: MyEditUser },
    { path: '/TotalAgentReport', component: MyTotalAgentReport },
    { path: '/Operation', component: MyOperation },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
