

<template>
    <div>
        <v-img class="mx-auto my-6" max-width="100" src="https://bo.app96.cc/Icon/Logo.png"></v-img>

        <v-form v-model="form" @submit.prevent="login" v-if="Form1">
            <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
                <div class="text-subtitle-1 text-medium-emphasis">Account</div>

                <v-text-field density="compact" placeholder="Account" v-model="Account"
                    prepend-inner-icon="mdi-account-outline" variant="outlined"></v-text-field>

                <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                    Password
                </div>

                <v-text-field v-model="Password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible ? 'text' : 'password'" density="compact" placeholder="Password"
                    prepend-inner-icon="mdi-lock-outline" variant="outlined"
                    @click:append-inner="visible = !visible"></v-text-field>

                <v-card class="mb-12" color="surface-variant" variant="tonal" v-if="errorText != ''">
                    <v-card-text class="text-medium-emphasis text-caption">
                        {{ errorText }}
                    </v-card-text>
                </v-card>

                <v-btn block class="mb-8" color="blue" size="large" variant="tonal" type="submit">
                    Log In
                </v-btn>

            </v-card>
        </v-form>
        <v-form v-model="form" @submit.prevent="login2" v-if="Form2">
            <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">

                <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                    <i v-if="ResponseData.Password2 == null">Set Second Password</i>
                    <i v-if="ResponseData.Password2 != null">Second Password</i>
                </div>

                <v-text-field v-model="Password2" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible ? 'text' : 'password'" density="compact" placeholder="Second Password"
                    prepend-inner-icon="mdi-lock-outline" variant="outlined"
                    @click:append-inner="visible = !visible"></v-text-field>

                <v-card class="mb-12" color="surface-variant" variant="tonal" v-if="errorText != '' || timeText != ''">
                    <v-card-text class="text-medium-emphasis text-caption" v-if="timeText != ''">
                        <strong class="text-red-lighten-1" v-if="ResponseData.Password2 == null">First time to setting
                            "Second Password"<br></strong>
                        {{ timeText }}
                    </v-card-text>
                    <v-card-text class="text-medium-emphasis text-caption" v-if="errorText != ''">
                        {{ errorText }}
                    </v-card-text>

                </v-card>

                <v-btn block class="mb-8" color="blue" size="large" variant="tonal" type="submit">
                    Log In
                </v-btn>

            </v-card>
        </v-form>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data: () => ({
        Form1: true,
        Form2: false,
        visible: false,
        errorText: "",
        timeText: '',
        ResponseData: {
            AgentID: "",
            AgentName: "",
            Password2: ''
        },
        Account: '',
        Password: '',
        Password2: ''

    }),
    methods: {
        login() {
            // 构建一个 FormData 对象
            const formData = new FormData();
            formData.append('Account', this.Account);
            formData.append('Password', this.Password);
            axios.post('https://amoikiss.com/API/Manager/Login.php', formData)
                .then(response => {

                    // 处理响应
                    if (!response.data.error) {

                        this.ResponseData.AgentID = response.data.AgentID;
                        this.ResponseData.AgentName = response.data.AgentName;
                        this.ResponseData.Password2 = response.data.Password2;
                        this.errorText = '';
                        this.Form1 = false;
                        this.Form2 = true;

                        // 启动计时器
                        let secondsLeft = 60;
                        const timer = setInterval(() => {
                            secondsLeft--;
                            // 更新错误文本显示剩余秒数
                            this.timeText = `Please complete within ${secondsLeft} seconds`;

                            if (secondsLeft === 0) {
                                // 取消计时器并重置错误文本
                                clearInterval(timer);
                                window.location.reload();
                                this.timeText = "Time expired. Please try again.";
                            }
                        }, 1000);
                    } else {
                        this.errorText = response.data.error
                    }
                })
                .catch(error => {
                    // 处理错误
                    console.log(error);
                });
        },
        login2() {
            if (this.ResponseData.Password2 == null && this.Password2 != '') {
                const formData = new FormData();
                formData.append('AgentID', this.ResponseData.AgentID);
                formData.append('Password2', this.Password2);
                axios.post('https://amoikiss.com/API/Manager/SetPassword2.php', formData)
                    .then(response => {
                        if (!response.data.error) {
                            localStorage.setItem('AgentID', this.ResponseData.AgentID);
                            localStorage.setItem('AgentName', this.ResponseData.AgentName);
                            window.location.reload();
                        }
                    })
                    .catch(error => {
                        // 处理错误
                        console.log(error);
                    });
            } else {
                if (this.Password2 != this.ResponseData.Password2) {
                    this.errorText = "Wrong Second Password!";
                } else {

                    localStorage.setItem('AgentID', this.ResponseData.AgentID);
                    localStorage.setItem('AgentName', this.ResponseData.AgentName);
                    window.location.reload();
                }
            }


        }
    }
}
</script>

                       
                       