<template>
    <!-- 您的模板內容 -->
    <v-container fluid>
        <div style="margin-bottom: 15px;">
            <h1 class="text-h5 mb-5">
                Score Log
                <v-chip class="ma-2" color="primary" variant="outlined">
                    <v-icon icon="mdi-account-outline"></v-icon>
                    {{ AgentName }}
                </v-chip>
            </h1>
            <v-row no-gutters>

                <v-col cols="12">
                    <v-card flat>
                        <div class="table-container">
                            <v-data-table id="UserTable" :headers="AgentHeaders" :items="AgentDesserts" :loading="loading">
                                <template v-slot:body="{ items }">
                                    <tr v-for="(item, index) in items" :key="index">
                                        <td>{{ item.From }}</td>
                                        <td>
                                            {{ item.OldFromScore }}
                                            <span class="mdi mdi-menu-right"></span>
                                            {{ item.NewFromScore }}
                                        </td>
                                        <td>{{ item.Adjust }}</td>
                                        <td :class="{ 'agent-color': item.ToType === 'Agent' }">{{ item.To }}
                                        </td>

                                        <td>
                                            {{ item.OldToScore }}
                                            <span class="mdi mdi-menu-right"></span>
                                            {{ item.NewToScore }}
                                        </td>

                                        <td>{{ item.Date }}</td>
                                    </tr>

                                </template>
                            </v-data-table>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
  
<script>
import axios from 'axios';
export default {
    data() {
        return {
            AgentHeaders: [
                { title: 'From' },
                { title: 'Score' },
                { title: 'Adjust' },
                { title: 'To' },
                { title: 'Score' },
                { title: 'Date' },
            ],
            AgentDesserts: [],
            loading: true,
            AgentID: localStorage.getItem('AgentID'),
            AgentName: localStorage.getItem('AgentName'),
        }
    },
    created() {
        this.getCookieValue();
        this.loadAgentData();
    },
    mounted() {
        // 在組件被加載到 DOM 中後執行的代碼
        this.handleURLParams();

    },
    methods: {
        async loadAgentData() {
            const requestData = { AgentID: this.AgentID }

            axios.post('https://amoikiss.com/API/Manager/AgentScoreLog.php', requestData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    console.log(response.data)
                    this.AgentDesserts = response.data;
                    this.loading = false;
                });

        },
        getCookieValue() {
            // 獲取 cookie 字串
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();

                if (cookie.startsWith("UserID=")) {
                    this.AgentID = cookie.substring("UserID=".length, cookie.length);
                    document.cookie = "UserID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                }

                if (cookie.startsWith("UserName=")) {
                    this.AgentName = cookie.substring("UserName=".length, cookie.length);
                    document.cookie = "UserName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                }
            }


        },
        handleURLParams() {
            var currentUrl = window.location.href;
            var urlParams = new URLSearchParams(currentUrl);

            urlParams.forEach((value, key) => {
                console.log(key, value);
                if (key === 'ID') {
                    this.IDss = value;
                }
                if (key === 'date') {
                    this.date = value;
                }
            });
        },
    },
};
</script>
  
<style>
.agent-color {
    color: red;
    /* 或者你想要的任何颜色 */
}
</style>
  