<template>
    <!-- 您的模板內容 -->
    <v-container fluid>
        <div style="margin-bottom: 15px;">
            <v-card class="mx-auto pa-5 pb-8" elevation="8" max-width="" rounded="lg">
                <h1 class="text-h5 mb-5">
                    Total Agent Report
                    <v-chip class="ma-2" color="primary" variant="outlined">
                        {{ UserName }}
                        <v-icon end icon="mdi-account-outline"></v-icon>
                    </v-chip>
                </h1>
                <v-row>

                    <v-col cols="12">
                        <v-autocomplete prepend-inner-icon="mdi-gamepad" label="Game" v-model="selectedGame"
                            :items="GameList" variant="outlined"></v-autocomplete>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="StartDate" label="Start Date" type="date"></v-text-field>

                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="EndDate" label="End Date" type="date"></v-text-field>

                            </v-col>
                        </v-row>

                        <v-card class="mb-4" color="red-lighten-1" variant="tonal" v-if="ErrorText != null">
                            <v-card-text class="text-medium-emphasis text-caption">
                                {{ ErrorText }}
                            </v-card-text>
                        </v-card>

                        <v-btn block class="mb-8" color="blue" size="large" variant="tonal" @click="ShowLog">Search</v-btn>

                    </v-col>
                </v-row>

            </v-card>
        </div>
        <!-- First Table -->
        <v-row no-gutters>
            <v-col cols="12">
                <v-card flat>
                    <div class="table-container">
                        <v-chip class="ma-2" color="success" variant="outlined">
                            <v-icon start icon="mdi-server-plus"></v-icon>
                            Total Win: {{ totalWin }}
                        </v-chip>
                        <v-data-table id="UserTable" :headers="Headers" :items="Desserts" :loading="loading"
                            loading-text="Loading... Please wait">
                            <template v-slot:body="{ items }">
                                <tr v-for="(item, index) in items" :key="index">
                                    <td>{{ item.AgentUserName }}</td>
                                    <td>{{ item.TotalBet }}</td>
                                    <td>{{ item.TotalWin }}</td>
                                </tr>

                            </template>
                        </v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
import axios from 'axios';
export default {
    data() {
        return {

            Headers: [
                { title: 'Agent' },
                { title: 'Bet' },
                { title: 'WinLose' },
            ],
            Desserts: [],
            GameList: ['EpicWin', 'BigPot'],
            ErrorText: null,
            UserName: null,
            UserID: null,
            selectedGame: "EpicWin",
            StartDate: null,
            EndDate: null,
            minDate: new Date(), // 最小可选择日期（当前日期）
            maxDate: new Date(new Date().getFullYear() + 1, 11, 31), // 最大可选择日期（一年后的12月31日）,
            loading: false,
        }
    },
    created() {

        this.getCookieValue();

    },
    mounted() {
        const today = new Date();

        // 獲取昨天的日期
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 7);
        const Startdate = new Date(yesterday);
        const Startyear = Startdate.getFullYear();
        const Startmonth = String(Startdate.getMonth() + 1).padStart(2, '0');
        const Startday = String(Startdate.getDate()).padStart(2, '0');
        const Startfulldate = Startyear + "-" + Startmonth + "-" + Startday;
        // 設置 StartDate 和 EndDate 變數
        this.StartDate = Startfulldate;
        // 獲取今天的日期
        const Enddate = new Date();
        Enddate.setDate(Enddate.getDate() - 1);
        const Endyear = Enddate.getFullYear();
        const Endmonth = String(Enddate.getMonth() + 1).padStart(2, '0');
        const Endday = String(Enddate.getDate()).padStart(2, '0');
        const Endfulldate = Endyear + "-" + Endmonth + "-" + Endday;

        this.EndDate = Endfulldate;
    },
    computed: {
        hasData() {
            return this.Desserts && this.Desserts.length > 0;
        },
        totalWin() {
            return this.Desserts.reduce((sum, item) => {
                // 确保TotalWin是一个数字
                const totalWin = Number(item.TotalWin) || 0;
                return sum + totalWin;
            }, 0);
        }
    },
    methods: {
        async ShowLog() {
            this.loading = true;
            this.ErrorText = null;
            this.Desserts = [];

            const formData = new FormData();

            // // 使用JavaScript内置的日期方法将日期格式化为 "YYYY-MM-DD"
            // const Startdate = new Date(this.StartDate);
            // const Startyear = Startdate.getFullYear();
            // const Startmonth = String(Startdate.getMonth() + 1).padStart(2, '0');
            // const Startday = String(Startdate.getDate()).padStart(2, '0');
            // const Startfulldate = Startyear + "-" + Startmonth + "-" + Startday;



            formData.append('AgentID', this.UserID);
            formData.append('Start', this.StartDate);
            formData.append('End', this.EndDate);
            formData.append('Game', this.selectedGame);
            console.log(this.UserID);
            axios.post('https://amoikiss.com/API/Manager/TotalReport.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    this.loading = false;
                    console.log(response.data);
                    if (response.data.length > 0) {
                        this.Desserts = response.data;
                    } else {
                        this.ErrorText = "No Data";
                    }

                    // if (!response.data[0].Error) {

                    // } else {
                    //     
                    // }

                });

        },
        getCookieValue() {
            // 獲取 cookie 字串
            // 
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();

                if (cookie.startsWith("UserID=")) {
                    this.UserID = cookie.substring("UserID=".length, cookie.length);
                    document.cookie = "UserID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                }

                if (cookie.startsWith("UserName=")) {
                    this.UserName = cookie.substring("UserName=".length, cookie.length);
                    document.cookie = "UserName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                }
            }
            if (this.UserID == null) {
                this.$router.push("/");
            }
        },
    },
};
</script>
  
<style>
.Mydate {
    margin: auto;
}
</style>
  