<template>
    <!-- 您的模板內容 -->
    <v-container fluid>
        <div style="margin-bottom: 15px;">
            <h1 class="text-h5 mb-5">
                Game Log

            </h1>
            <v-card class="mx-auto pa-5 pb-8" elevation="8" max-width="" rounded="lg">
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete prepend-inner-icon="mdi-account" label="Player" v-model="selectedPlayer"
                            :items="PlayerList" item-title="UserName" item-value="PlayerID"
                            variant="outlined"></v-autocomplete>
                        <v-autocomplete prepend-inner-icon="mdi-gamepad" label="Game" v-model="selectedGame"
                            :items="GameList" variant="outlined"></v-autocomplete>
                        <v-text-field v-model="selectedDate" label="Date" type="date" variant="outlined"></v-text-field>
                        <v-card class="mb-4" color="red-lighten-1" variant="tonal" v-if="ErrorText != null">
                            <v-card-text class="text-medium-emphasis text-caption">
                                {{ ErrorText }}
                            </v-card-text>
                        </v-card>
                        <v-card class="mb-12" color="surface-variant" variant="tonal">
                            <v-card-text class="text-medium-emphasis text-caption">
                                * Search 1 Day Player data only
                            </v-card-text>
                        </v-card>
                        <v-btn block class="mb-8" color="blue" size="large" variant="tonal"
                            :disabled="selectedPlayer == null || selectedGame == null" @click="ShowGamingLog">Search</v-btn>

                    </v-col>
                </v-row>

            </v-card>
        </div>
        <!-- First Table -->
        <v-row no-gutters>
            <v-col cols="12">
                <v-card flat>
                    <div class="table-container">
                        <v-data-table id="UserTable" :headers="Headers" :items="Desserts" :loading="loading"
                            loading-text="Loading... Please wait">
                            <template v-slot:body="{ items }">
                                <tr v-for="(item, index) in items" :key="index">
                                    <td>{{ item.ID }}</td>
                                    <td>{{ item.Player }}</td>
                                    <td>{{ item.Bet }}</td>
                                    <td>{{ item.Payout }}</td>
                                    <td>{{ item.WinLose }}</td>
                                    <td>{{ item.DateTime }}</td>
                                    <td>{{ item.Game }}</td>
                                    <!-- <td>({{ item.ToType }}){{ item.To }}</td>
                                    <td>{{ item.Log }}</td>
                                    <td>{{ item.Total }}</td> -->

                                </tr>

                            </template>
                        </v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
import axios from 'axios';
export default {
    data() {
        return {

            Headers: [
                { key: 'ID', title: 'ID' },
                { key: 'Player', title: 'Player' },
                { key: 'Bet', title: 'Bet' },
                { key: 'Payout', title: 'Payout' },
                { key: 'WinLose', title: 'WinLose' },
                { key: 'DateTime', title: 'DateTime' },
                { key: 'Game', title: 'Game' },
            ],
            Desserts: [],
            PlayerList: [],
            GameList: ['EpicWin', 'BigPot'],
            ErrorText: null,
            selectedPlayer: null,

            selectedGame: "EpicWin",
            selectedDate: new Date(),
            minDate: new Date(), // 最小可选择日期（当前日期）
            maxDate: new Date(new Date().getFullYear() + 1, 11, 31), // 最大可选择日期（一年后的12月31日）,
            loading: false,
            IDss: '',
            date: '',
        }
    },
    created() {

        this.loadPlayerList();
        this.getCookieValue();
    },
    mounted() {

        // 獲取今天的日期
        const selectedDate = new Date();
        selectedDate.setDate(selectedDate.getDate() - 1);
        const Endyear = selectedDate.getFullYear();
        const Endmonth = String(selectedDate.getMonth() + 1).padStart(2, '0');
        const Endday = String(selectedDate.getDate()).padStart(2, '0');
        const Endfulldate = Endyear + "-" + Endmonth + "-" + Endday;

        this.selectedDate = Endfulldate;
    },
    methods: {
        getPlayerInfo() {
            console.log("Selected PlayerID:", this.selectedPlayer)
        },
        async loadPlayerList() {
            const formData = new FormData();
            formData.append('AgentID', localStorage.getItem('AgentID'));
            axios.post('https://amoikiss.com/API/Manager/SearchPlayerList.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    this.PlayerList = response.data;
                    // this.selectedPlayer = "5";
                });

        },
        async ShowGamingLog() {
            this.loading = true;
            this.ErrorText = null;
            this.Desserts = [];

            const formData = new FormData();

            // 使用JavaScript内置的日期方法将日期格式化为 "YYYY-MM-DD"
            const date = new Date(this.selectedDate);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const fulldate = year + "-" + month + "-" + day;

            formData.append('PlayerID', this.selectedPlayer);
            formData.append('Game', this.selectedGame);
            formData.append('Date', fulldate);
            axios.post('https://amoikiss.com/API/Manager/ShowGamingLog.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    this.loading = false;

                    if (!response.data[0].Error) {
                        this.Desserts = response.data;
                    } else {
                        this.ErrorText = response.data[0].Error;
                    }

                });

        },
        getCookieValue() {
            // 獲取 cookie 字串
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith("PlayerID=")) {
                    this.selectedPlayer = cookie.substring("PlayerID=".length, cookie.length);
                    document.cookie = "PlayerID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                }
            }
        },
    },
};
</script>
  
<style></style>
  