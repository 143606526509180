
<template>
  <Login v-if="!AgentID" />
  <v-card v-else>
    <v-layout>

      <v-navigation-drawer image="https://amoikiss.com/Img/Navbar.jpg" v-model="drawer" expand-on-hover
        :rail="!$vuetify.lgAndUp" theme="dark">
        <v-list>
          <v-list-item prepend-avatar="https://bo.app96.cc/Icon/Logo.png" title="Amoikiss Manager"></v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item @click="selectPage('/')" prepend-icon="mdi-account" title="User" value=""></v-list-item>
          <v-list-item @click="selectPage('/ScoreLog')" prepend-icon="mdi-material-ui" title="Score Log"
            value="ScoreLog"></v-list-item>
          <v-list-item @click="selectPage('/GameLog')" prepend-icon="mdi-material-ui" title="Game Log"
            value="GameLog"></v-list-item>
          <v-list-item @click="selectPage('/Operation')" prepend-icon="mdi-card-search-outline" title="Operation"
            value="Operation"></v-list-item>
          <!-- <v-list-item @click="selectPage('/SubAccount')" prepend-icon="mdi-material-ui" title="Sub Account"
            value="SubAccount"></v-list-item> -->
          <!-- <v-list-item @click="selectPage('/starred')" prepend-icon="mdi-star" title="Starred"
            value="starred"></v-list-item> -->

        </v-list>

        <!-- 添加 LOGOUT 选项 -->
        <template v-slot:append>
          <v-list density="compact" nav>
            <v-list-item prepend-avatar="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
              :title="AgentName"></v-list-item>
            <v-list-item prepend-icon="mdi-logout" title="Logout" value="myfiles" @click="logout"></v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>

      <v-app-bar app v-if="!drawer">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-app-bar-title>My App</v-app-bar-title>
      </v-app-bar>
      <v-main style="min-height: 100vh">
        <router-view></router-view>
      </v-main>
    </v-layout>
  </v-card>
</template>
<script>
import axios from 'axios';
import Login from "@/components/MyLogin.vue";
export default {
  components: {
    Login,
  },
  data() {
    return {
      AgentID: localStorage.getItem('AgentID'),
      AgentName: localStorage.getItem('AgentName'),
      drawer: null,
    };
  },
  created() {
    if (this.AgentID != null) {
      this.checkAccount();
    }

  },
  methods: {
    checkAccount() {
      const formData = new FormData();
      formData.append('AgentID', this.AgentID);
      axios.post('https://amoikiss.com/API/Manager/CheckAgentStatus.php', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(response => {
          if (!response.data.success) {
            this.logout();
          }
        });


    },
    selectPage(route) {
      this.$router.push(route);
    },
    logout() {
      localStorage.removeItem('AgentID');
      localStorage.removeItem('AgentName');
      window.location.reload();
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  display: none;
}
</style>
